import { infoBubleMaxWidth } from "../constantes";
import fetch_utils from "../fetch_utils";
import ITrads from "../ITrads";

const iconPaneliste = () => {
  return {
    path: google.maps.SymbolPath.CIRCLE,
    fillColor: "#00ff00",
    fillOpacity: 0.6,
    scale: 7,
    strokeWeight: 0.75,
    strokeColor: "#ffffff",
  };
};

const colorPanelisteWithAppels = "#e31524";

const sansInfos = (
  map: google.maps.Map,
  point: google.maps.LatLng | google.maps.LatLngLiteral,
  draggable = false
) => {
  map.setCenter(point);
  return new google.maps.Marker({
    position: point,
    map: map,
    icon: iconPaneliste(),
    draggable,
  });
};

const avecInfos = (
  map: google.maps.Map,
  point: google.maps.LatLng | google.maps.LatLngLiteral,
  infotab: string[],
  traductions: ITrads
) => {
  const marker = new google.maps.Marker({
    position: point,
    map,
    icon: iconPaneliste(),
  });

  const infoBubble = new InfoBubble({
    maxWidth: infoBubleMaxWidth,
  });
  if (infotab[0]) {
    infoBubble.addTab(traductions["Infos"], infotab[0]);
  }
  if (infotab[1]) {
    infoBubble.addTab(traductions["Appels"], infotab[1]);
  }

  google.maps.event.addListener(marker, "click", () => {
    if (!infoBubble.isOpen()) {
      infoBubble.open(map, marker);
    }
  });
  return marker;
};

const avecInfosRondsPropotionnelAppels = (
  map: google.maps.Map,
  point: google.maps.LatLng | google.maps.LatLngLiteral,
  infotab: Array<string>,
  generateChartUrl: string | null,
  nb_appels: number,
  nb_appels_total: number,
  traductions: ITrads
) => {
  let icon = iconPaneliste();
  if (nb_appels > 0) {
    let ratio = (nb_appels / nb_appels_total) * 100;
    ratio = Math.round(ratio / 2);
    let scale = ratio + 5;
    if (scale > 30) {
      scale = 30;
    }
    icon = { ...icon, scale, fillColor: colorPanelisteWithAppels };
  }
  const marker = new google.maps.Marker({
    position: point,
    icon,
    map,
  });
  marker.addListener("click", async () => {
    const infoBubble = new InfoBubble({ maxWidth: 300 });
    infoBubble.addTab(traductions["Infos"], infotab[0]);
    if (infotab[1]) {
      infoBubble.addTab(traductions["Appels"], infotab[1]);
    }
    if (generateChartUrl !== null) {
      const chartData = await fetch_utils.boilerplateGet(generateChartUrl);
      if (chartData === false) {
        return;
      }
      infoBubble.addTab(
        traductions["Carto"],
        `<div class='gmapmarker'>${chartData}</div>`
      );
    }
    if (!infoBubble.isOpen()) {
      infoBubble.open(map, marker);
    }
  });
  marker.setMap(map);
  return marker;
};

export default {
  avecInfos,
  avecInfosRondsPropotionnelAppels,
  sansInfos,
};
