import "datatables.net";

import * as datatablesLanguageEnGb from "datatables.net-plugins/i18n/en-GB.json";
import * as datatablesLanguageEsES from "datatables.net-plugins/i18n/es-ES.json";
import * as datatablesLanguageFrFr from "datatables.net-plugins/i18n/fr-FR.json";
import * as datatablesLanguageItIt from "datatables.net-plugins/i18n/it-IT.json";
// jquery 3.6.1 not compatible with datables 1.13.1
import * as $ from "jquery";

/**
 * DataTable needs JQuery event if it could be loaded without JQuery
 */
export default ({
  htmlId = "#table_id",
  languageCode = "fr_FR",
  datablesOptions = {} /* DataTables.Settings (not exported) */,
}) => {
  let datablesLanguage: any;
  switch (languageCode) {
    case "fr":
    case "fr_FR":
    case "fr_CA":
      datablesLanguage = datatablesLanguageFrFr;
      break;
    case "en":
    case "en_US":
      datablesLanguage = datatablesLanguageEnGb;
      break;
    case "es":
    case "es_ES":
      datablesLanguage = datatablesLanguageEsES;
      break;
    case "it":
    case "it_IT":
      datablesLanguage = datatablesLanguageItIt;
      break;
    default:
      datablesLanguage = datatablesLanguageFrFr;
      break;
  }
  const options = {
    language: datablesLanguage,
    ...datablesOptions,
  };
  return $(htmlId).DataTable(options);
};
