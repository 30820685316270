import { infoBubleMaxWidth } from "../constantes";
import fetch_utils from "../fetch_utils";

const pathname = "/Enqueteur/mcd/point_d_interet_ajax_crud.php";
const pointsDInteret: google.maps.Marker[] = [];

const placePointDInteret = (param: {
  map: google.maps.Map;
  label: string,
  latitude: number;
  longitude: number;
  score: number;
  idPoi: number;
  draggable: boolean;
}): google.maps.Marker => {
  let fillColor = "";
  if (param.score === 0) {
    fillColor = "green";
  } else if (param.score > 0 && param.score <= 3) {
    fillColor = "lightgreen";
  } else if (param.score > 3 && param.score <= 5) {
    fillColor = "yellow";
  } else if (param.score > 5 && param.score <= 7) {
    fillColor = "orange";
  } else if (param.score > 7 && param.score <= 10) {
    fillColor = "red";
  }
  const svgMarker = {
    path: `M12,2c1.9,0,3.6,0.7,4.9,2s2,3,2,4.9c0,1-0.2,2.1-0.7,3.3s-1.1,2.4-1.8,3.5c-0.7,1.1-1.4,2.1-2,3.1
      c-0.7,1-1.2,1.7-1.7,2.3L12,22c-0.2-0.2-0.4-0.5-0.8-0.9c-0.3-0.4-0.9-1.1-1.7-2.2S8,16.8,7.4,15.8s-1.2-2.2-1.7-3.4S5,10,5,9
      c0-1.9,0.7-3.6,2-4.9S10.1,2,12,2L12,2z`,
    fillColor,
    fillOpacity: 0.6,
    strokeWeight: 0.75,
    strokeColor: "#ffffff",
    anchor: new google.maps.Point(12.5, 12.5),
  };
  const marker = new google.maps.Marker({
    position: new google.maps.LatLng(param.latitude, param.longitude),
    map: param.map,
    icon: svgMarker,
    draggable: param.draggable,
  });
  const infoBubble = new InfoBubble({
    maxWidth: infoBubleMaxWidth,
  });
  infoBubble.addTab(
    "Infos",
    `
    <ul>
      <li><strong>${param.label}</strong></li>
      <li>Latitude : ${param.latitude}<li>
      <li>Longitude : ${param.longitude}<li>
      <li>Score : ${param.score}<li>
      <li>IdPOI : ${param.idPoi}</li>
    </ul>
  `
  );
  google.maps.event.addListener(marker, "click", () => {
    if (!infoBubble.isOpen()) {
      infoBubble.open(param.map, marker);
    }
  });
  return marker;
};

const retrieve = async (
  map: google.maps.Map,
  payload: {
    type: "read_all" | "read";
    [key: string]: string | number;
  },
  draggable = false
) => {
  const dataRaw = await fetch_utils.boilerplatePost(pathname, payload);
  if (dataRaw === false) {
    return;
  }
  let json: {
    IdPOI: number;
    Label: string,
    Latitude: number;
    Longitude: number;
    Score: number;
  }[] = null;
  try {
    json = JSON.parse(dataRaw);
  } catch (error) {
    fetch_utils.catchJsonParseError(pathname, dataRaw);
    return;
  }
  json.forEach((value) => {
    pointsDInteret.push(
      placePointDInteret({
        map,
        label: value.Label,
        latitude: value.Latitude,
        longitude: value.Longitude,
        score: value.Score,
        idPoi: value.IdPOI,
        draggable,
      })
    );
  });
};

const readAll = async (map: google.maps.Map) => {
  await retrieve(map, { type: "read_all" });
  if(pointsDInteret.length > 0) {
    const checkboxPoi = document.getElementById("checkboxPoi");
    checkboxPoi.style.display = "table-row";
  }
};

const updateLocation = async (
  idPoi: number,
  latLng: google.maps.LatLng,
  map: google.maps.Map,
) => {
  const dataRaw = await fetch_utils.boilerplatePost(pathname, {
    type: "update_location",
    idPoi,
    latitude: latLng.lat(),
    longitude: latLng.lng(),
  });
  if (dataRaw === false) {
    return;
  }
  display(map, false);
  readOne(map, idPoi);
  alert(`Déplacement du point d'intérêt enregistré !`);
};

const readOne = async (map: google.maps.Map, idPoi: number) => {
  await retrieve(map, { type: "read", idPoi: idPoi }, true);
  pointsDInteret.forEach((aPointDInteret) => {
    aPointDInteret.addListener(
      "dragend",
      async (e: google.maps.MapMouseEvent) => {
        await updateLocation(idPoi, e.latLng, map);
      }
    );
  });
};

const updateLocationByGeocoding = (map: google.maps.Map, idPoi: number) => {
  document
    .getElementById("addressForm")
    .addEventListener("submit", (e: SubmitEvent) => {
      e.preventDefault();
      const formData = new FormData(e.target as HTMLFormElement);
      let address = "";
      formData.forEach((value) => {
        address += `${value},`;
      });
      address = address.substring(0, address.length - 1);
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode(
        {
          address,
        },
        async (results, status) => {
          if (status != google.maps.GeocoderStatus.OK) {
            alert(
              `Adresse "${address}" non trouvée. Veuillez vérifier vos informations`
            );
            return;
          }
          display(map, false);
          await updateLocation(idPoi, results[0].geometry.location, map);
        }
      );
      return false;
    });
};

const display = (map: google.maps.Map, display: boolean) => {
  pointsDInteret.forEach((aPointDInteret) => {
    display ? aPointDInteret.setMap(map) : aPointDInteret.setMap(null);
  });
};

const updateLabel = async (id: number, inputHTML: HTMLInputElement) => {
  const valueToUpdate = inputHTML.value;
  const isValueValid = valueToUpdate !== "";
  fetch_utils.boilerplatePostWithMessageToUser(
    pathname,
    { type: "update_label", id, valueToUpdate },
    isValueValid,
    inputHTML
  );
};

const updateScore = async (id: number, inputHTML: HTMLInputElement) => {
  const valueToUpdate = Number.parseFloat(inputHTML.value);
  const isValueValid =
    !isNaN(valueToUpdate) &&
    Number.isInteger(valueToUpdate) &&
    valueToUpdate >= 0 &&
    valueToUpdate <= 10;
  fetch_utils.boilerplatePostWithMessageToUser(
    pathname,
    {
      type: "update_score",
      id,
      valueToUpdate,
    },
    isValueValid,
    inputHTML
  );
};

export default {
  readAll,
  readOne,
  display,
  updateLocationByGeocoding,
  updateLabel,
  updateScore,
};
