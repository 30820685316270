/* =============================================================================
 *         AUTHOR: Jérémy
 *        CREATED: 2017
 *       MODIFIED: Julio (2022) (adaptation to TypeScript and translations)
 * ============================================================================
 */
declare global {
  interface Window {
    JSZip: any;
  }
}

// See https://datatables.net/forums/discussion/49747/excel-button-not-showing-up-using-webpack
import * as JSZip from "jszip";
window.JSZip = JSZip;
import "datatables.net-buttons";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";

import * as $ from "jquery";

import datatables from "./datatables";
import ITrads from "./ITrads";

export default (traductions: ITrads) => {
  const table = datatables({
    htmlId: "#datable",
    languageCode: "<?= LanguageController::getLanguageCode() ?>",
    datablesOptions: {
      dom: "Bfrtlip",
      buttons: ["csv", "excel", "print"],
      columnDefs: [
        {
          targets: 0,
          type: "date",
          orderData: [0, 1],
          render: {
            display: function (data: string) {
              return new Date(data).toLocaleDateString();
            },
            sort: function (data: string) {
              return data;
            },
          },
        },
        { targets: 1, orderData: [0, 1] },
      ],
      ordering: true,
      order: [
        [0, "desc"],
        [1, "desc"],
      ],
      lengthMenu: [
        [10, 25, 50, 100, -1],
        [10, 25, 50, 100, traductions["Tous"]],
      ],
    },
  });
  $("a.toggle-vis").on("click", function (e) {
    e.preventDefault();

    // Get the column API object
    const column = table.column($(this).attr("data-column"));

    // Toggle the visibility
    column.visible(!column.visible());
  });

  $("tfoot th").each(function () {
    let input: JQuery<HTMLInputElement>;
    if ($(this).data("filter") == "text") {
      input = $('<input type="text" />');
      input.on("keyup change", function () {
        table.column($(this).parent().data("col")).search(this.value).draw();
      });
    } else if ($(this).data("filter") == "date") {
      input = $("<select/>");
      input.append("<option value='all'></option>");
      input.append(`<option value='week'>${traductions["semaine"]}</option>`);
      input.append(
        `<option value='week-end'>${traductions["weekend"]}</option>`
      );
      input.on("keyup change", function () {
        const that = this;
        // @ts-ignore
        $.fn.dataTable.ext.search.push(function (
          _: any,
          __: string[],
          ___: number,
          rowData: string[],
          _____: number
        ) {
          const dateString = rowData[$(that).parent().data("col")];
          const my_date = new Date(dateString);
          if (that.value == "week") {
            if (my_date.getDay() != 0 && my_date.getDay() != 6) {
              return true;
            } else {
              return false;
            }
          } else if (that.value == "week-end") {
            if (my_date.getDay() == 0 || my_date.getDay() == 6) {
              return true;
            } else {
              return false;
            }
          } else {
            return true;
          }
        });
        table.draw();
      });
    } else if ($(this).data("filter") == "heure") {
      input = $('<input type="time" />');
      input.on("keyup change", function () {
        table.columns($(this).parent().data("col")).search(this.value).draw();
      });
    } else if ($(this).data("filter") == "type") {
      input = $("<select />");
      input.append($("<option value='' selected></option>"));

      table
        .column($(this).data("col"))
        .data()
        .unique()
        .each(function (key) {
          if (key.match(/</)) {
            key = key.substring(key.indexOf(">") + 1, key.indexOf("</", 3));
          }
          if (key !== "") {
            input.append($("<option value='" + key + "'>" + key + "</option>"));
          }
        });

      input.on("keyup change", function () {
        table.columns($(this).parent().data("col")).search(this.value).draw();
      });
    } else if ($(this).data("filter") == "select") {
      input = $("<select />");
      input.append($("<option value='' selected></option>"));

      table
        .column($(this).data("col"))
        .data()
        .unique()
        .each(function (key) {
          if (key.match(/<div/)) {
            key = key.substring(key.indexOf(">") + 1, key.indexOf("<", 4));
          }
          if (key !== "") {
            input.append($("<option value='" + key + "'>" + key + "</option>"));
          }
        });

      input.on("keyup change", function () {
        table.column($(this).parent().data("col")).search(this.value).draw();
      });
    }
    $(this).empty();
    input.appendTo($(this));
  });
};
