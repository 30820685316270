export default (
  map: google.maps.Map | null,
  str_pts: string,
  couleur: string,
): google.maps.Polygon => {
  const G_StrokeWeight = 2;
  const G_StrokeOpacity = 0.4;
  const pdts = new Array();
  if (str_pts != "") {
    const tmp_points = str_pts.split(";");
    for (let x = 0; x < tmp_points.length - 1; x += 2) {
      pdts[pdts.length] = new google.maps.LatLng(
        parseFloat(tmp_points[x]),
        parseFloat(tmp_points[x + 1])
      );
    }
    const polygon = new google.maps.Polygon({
      paths: pdts,
      strokeColor: couleur,
      strokeOpacity: G_StrokeOpacity,
      strokeWeight: G_StrokeWeight,
      fillColor: couleur,
      fillOpacity: 0.5,
    });
    polygon.setMap(map);
    return polygon;
  }
};
