declare global {
  interface Window {
    PHRASEAPP_CONFIG: { projectId: string };
  }
}

const languageSelector = () => {
  const languageButton = document.querySelector(".language-button");
  const languageMenu = document.querySelector(".language-menu");

  languageButton.addEventListener("click", () => {
    languageButton.classList.toggle("active");
    languageMenu.classList.toggle("active");
  });

  document.addEventListener("click", (event: any) => {
    if (
      !languageButton.contains(event.target) &&
      !languageMenu.contains(event.target)
    ) {
      languageButton.classList.remove("active");
      languageMenu.classList.remove("active");
    }
  });
};

const phraseInContextEditor = (phraseProjectId: string) => {
  window.PHRASEAPP_CONFIG = {
    projectId: phraseProjectId,
  };

  (function () {
    var phraseapp = document.createElement("script");
    phraseapp.type = "text/javascript";
    phraseapp.async = true;
    phraseapp.src = [
      "https://",
      "phraseapp.com/assets/in-context-editor/2.0/app.js?",
      new Date().getTime(),
    ].join("");
    var s = document.getElementsByTagName("script")[0];
    s.parentNode.insertBefore(phraseapp, s);
  })();
};

export default (phraseInContextEditorParam: {
  shouldLoadPhraseScript: boolean;
  phraseProjectId: string;
}) => {
  languageSelector();

  if (phraseInContextEditorParam.shouldLoadPhraseScript) {
    phraseInContextEditor(phraseInContextEditorParam.phraseProjectId);
  }
};
