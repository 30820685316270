import "../scss/index.scss";
import common, { convertISO8601 } from "./common";
import confirmDeletion from './confirmDeletion';
import datatables from "./datatables";
import googleMap from "./googleMap/index";
import page_enqueteur_tableau_datatables from "./page_enqueteur_tableau_datatables";
import page_enqueteur_mcd_log from "./page_enqueteur_mcd_log";
import page_enqueteur_risque_olfactif from "./page_enqueteur_risque_olfactif";
import page_index from "./page_index";
import setupTranslation from "./setupTranslation";
import formUtils from './form_utils';

export {
  common,
  convertISO8601,
  datatables,
  page_index,
  page_enqueteur_tableau_datatables,
  page_enqueteur_mcd_log,
  page_enqueteur_risque_olfactif,
  confirmDeletion,
  googleMap,
  setupTranslation,
  formUtils,
};

