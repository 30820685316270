import { polyfill as smoothscrollPolyfill } from 'smoothscroll-polyfill';
import videojs from 'video.js';

/**
 * Source: https://webdesign.tutsplus.com/tutorials/smooth-scrolling-vanilla-javascript--cms-35165
 */
const smoothScrolling = () => {
  const clickHandler = function clickHandler (e: Event) {
    e.preventDefault();
    const href = this.getAttribute("href");
    if (href === "#") {
      return;
    }
    const offsetTop = document.querySelector(href).offsetTop;

    scroll({
      top: offsetTop,
      behavior: "smooth",
    });
  };

  const links = document.querySelectorAll('a[href^="#"]');

  for (let i = 0; i < links.length; i++) {
    const link = links[i];
    link.addEventListener("click", clickHandler);
  }
};

/**
 * https://codepen.io/malsu/pen/VwKzoPG
 */
const scrollIndicators = () => {
  const navHighlighter = () => {
    // Get current scroll position
    let scrollY = window.pageYOffset;

    // Now we loop through sections to get height, top and ID values for each
    sections.forEach((current: HTMLElement) => {
      const sectionHeight = current.offsetHeight;
      const sectionTop = current.offsetTop - 50;
      const sectionId = current.getAttribute("id");

      const menuItem = document.querySelector(
        ".scroll a[href*=" + sectionId + "]"
      );

      if (menuItem !== null) {
        /*
              - If our current scroll position enters the space where current section on screen is, add .active class to corresponding navigation link, else remove it
              - To know which link needs an active class, we use sectionId variable we are getting while looping through sections as an selector
              */
        if (scrollY > sectionTop && scrollY <= sectionTop + sectionHeight) {
          menuItem.classList.add("active");
        } else {
          menuItem.classList.remove("active");
        }
      }
    });
  };

  // Get all sections that have an ID defined
  const sections = document.querySelectorAll("section[id]");

  // Add an event listener listening for scroll
  window.addEventListener("scroll", navHighlighter);
};

const scrollToTop = () => {
  const scrollButton = document.querySelector(".scroll-to-top");
  const section2: HTMLElement = document.querySelector(
    "section:nth-of-type(2)"
  );

  const toggleScrollButton = () => {
    if (window.scrollY >= section2.offsetTop / 1.75)
      scrollButton.classList.add("active");
    else scrollButton.classList.remove("active");
  };

  window.addEventListener("scroll", toggleScrollButton);

  toggleScrollButton();
};

const startVideojs = () => {
  videojs(document.getElementById("videojs"));
}

export default () => {
  smoothscrollPolyfill();
  smoothScrolling();
  scrollIndicators();
  scrollToTop();
  startVideojs();
}
