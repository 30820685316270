import datatables from "./datatables";

export default () => {
  datatables({
    htmlId: "#datable",
    languageCode: "<?= LanguageController::getLanguageCode() ?>",
    datablesOptions: {
      // columnDefs: [
      //   {
      //     targets: 0,
      //     type: "date",
      //     orderData: [0, 1],
      //     render: {
      //       display: function (data: string) {
      //         return new Date(data).toLocaleDateString();
      //       },
      //       sort: function (data: string) {
      //         return data;
      //       },
      //     },
      //   },
      //   { targets: 1, orderData: [0, 1] },
      // ],
      ordering: true,
      order: [
        [0, "desc"],
        [1, "desc"],
      ],
    },
  });

};
