const formLabelRequireHint = () => {
  document
    .querySelectorAll("input[required], select[required]")
  document
    .querySelectorAll("input[required], select[required]")
    .forEach((element) => {
      const label = element.previousElementSibling;
      if(!label || label.tagName !== "LABEL") {
        return;
      }
      label.classList.add("label-required-hint");
    });
};

export default {
  formLabelRequireHint,
}
