/**
 * https://stackoverflow.com/questions/37121301/how-to-check-if-the-response-of-a-fetch-is-a-json-object-in-javascript
 */
const catchHttpError = async (response: Response) => {
  console.error(response);
  let errorMessageRaw = null;
  try {
    errorMessageRaw = await response.text();
  } catch {
    alert(
      `Fail because its got the response: ${response.statusText} (see console.). Unable to fetch body of the response.`
    );
    return;
  }
  if (!errorMessageRaw || errorMessageRaw.trim() === "") {
    alert(
      `Fail because its got the response: ${response.statusText} (see console.). HTTP body is empty.`
    );
    return;
  }
  try {
    const errorMessageJson: { error: string } = JSON.parse(errorMessageRaw);
    alert(
      `Fail because its got the response: \n\n\n ${errorMessageJson.error}`
    );
  } catch (error) {
    console.error(errorMessageRaw);
    alert(`Fail because its got the response: \n\n\n ${errorMessageRaw}`);
  }
};

const boilareplateResponse = async (
  response: Response
): Promise<string | false> => {
  if (!response.ok) {
    catchHttpError(response);
    return false;
  }
  try {
    return await response.text();
  } catch (error) {
    alert(`Fail to retrieve response body (see console)`);
    console.error(response, error);
    return false;
  }
};

const boilerplateGet = async (url: string): Promise<string | false> => {
  let response: Response = null;
  try {
    response = await fetch(url);
  } catch (error) {
    console.error(error);
    alert(error);
    return false;
  }
  return boilareplateResponse(response);
};

const boilerplatePost = async (
  url: string,
  payload: any
): Promise<string | false> => {
  let response: Response = null;
  try {
    response = await fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(payload),
    });
  } catch (error) {
    console.error(error);
    alert(error);
    return false;
  }
  return boilareplateResponse(response);
};

const boilerplatePostWithMessageToUser = async (
  url: string,
  payload: {
    type: string;
    id: number,
    [key: string]: string | number;
  },
  isValueValid: boolean,
  elementHTML: HTMLElement,
): Promise<string | false> => {
  const successHTMLDiv = elementHTML.nextElementSibling;
  const errorHTMLDiv = elementHTML.nextElementSibling.nextElementSibling;
  if (!isValueValid) {
    successHTMLDiv.classList.add("d-none");
    errorHTMLDiv.classList.remove("d-none");
    return;
  }
  const postResponse = await boilerplatePost(url, payload);
  if(postResponse !== false) {
    successHTMLDiv.classList.remove("d-none");
    errorHTMLDiv.classList.add("d-none");
  }
};

const getFullUrlFromPathname = (pathname: string) => {
  return `${window.location.protocol}//${window.location.hostname}/${pathname}`;
};

const catchJsonParseError = (pathname: string, dataRaw: string) => {
  console.error(pathname, dataRaw);
  alert(
    `Fail to parse output of "${getFullUrlFromPathname(pathname)}".
      Row output is \n ${dataRaw}
    `
  );
};

export default {
  boilerplateGet,
  boilerplatePost,
  boilerplatePostWithMessageToUser,
  getFullUrlFromPathname,
  catchJsonParseError,
};
