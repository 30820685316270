import { defineCustomElements } from "ionicons/dist/loader";

// See also
// https://github.com/ionic-team/ionicons/issues/769
// https://github.com/ionic-team/ionicons/issues/877
const instantiateIonIcons = () => {
  defineCustomElements(window, {
    /** Copy from icons from ../node_modules/ionicons/dist/svg */
    resourcesUrl: "/assets/ionicons/",
  });
};

const googleAnalytics = () => {
  var _gaq = _gaq || [];
  _gaq.push(["_setAccount", "UA-21666965-1"]);
  _gaq.push(["_trackPageview"]);

  (function () {
    var ga = document.createElement("script");
    ga.type = "text/javascript";
    ga.async = true;
    ga.src =
      ("https:" == document.location.protocol ? "https://ssl" : "http://www") +
      ".google-analytics.com/ga.js";
    var s = document.getElementsByTagName("script")[0];
    s.parentNode.insertBefore(ga, s);
  })();
};

const customAlert = () => {
  const closeButtons = document.querySelectorAll(".alert__closebtn");
  closeButtons.forEach((button: HTMLDivElement) => {
    button.addEventListener("click", (e) => {
      const div = (e.currentTarget as HTMLDivElement).parentElement;
      div.style.opacity = "0";
      setTimeout(() => {
        div.style.display = "none";
      }, 600);
    });
  });
};

const runConvertISO8601 = ({
  element,
  displayOnlyDate = false,
  displayOnlyHour = false,
}) => {
  const dateISO = element.innerText;
  if (dateISO.length < 9) {
    console.error(`${dateISO} inconrect. Should be formatted YYYY-MM-DDThh:mm`);
    return "";
  }
  const dateTime = new Date(dateISO);
  const date = dateTime.toLocaleDateString();
  const time = `${dateTime.getHours()}:${dateTime.getMinutes()}`;
  if (displayOnlyDate && displayOnlyHour) {
    console.error("fonction params are not correct");
    return "";
  } else if (displayOnlyDate) {
    element.innerHTML = date;
  } else if (displayOnlyHour) {
    element.innerHTML = time;
  } else {
    element.innerHTML = `${date} ${time}`;
  }
};

/**
 * TODO local timezone
 */
export const convertISO8601 = () => {
  document
    .querySelectorAll(
      "span[date-should-be-formatted], option[date-should-be-formatted]"
    )
    .forEach((element: HTMLElement) => {
      runConvertISO8601({ element });
    });
  document
    .querySelectorAll(
      "span[date-should-be-formatted-only-date], option[date-should-be-formatted-only-date]"
    )
    .forEach((element: HTMLElement) => {
      runConvertISO8601({ element, displayOnlyDate: true });
    });
  document
    .querySelectorAll(
      "span[date-should-be-formatted-only-time], option[date-should-be-formatted-only-time]"
    )
    .forEach((element: HTMLElement) => {
      runConvertISO8601({ element, displayOnlyHour: true });
    });
};

export default () => {
  instantiateIonIcons();
  googleAnalytics();
  customAlert();
};
